.loading-container {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.list__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.list__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 20px;
    width: 300px;
    height: 200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    list-style: none;
}

.list__btn {
    margin-top: 30px;
    margin-left: 5px;
}

.dot--red {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    margin-right: 10px;
}

.dot--green {
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 50%;
    margin-right: 10px;
}

.item__info {
    display: flex;
    align-items: center;
}

@media (max-width: 1000px) {
    .header__mobile{
        text-align: center;
    }
    .dot--red {
        display: none;
    }
    
    .dot--green {
        display: none;
    }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/