.item__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 20px 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.item__message {
  margin-bottom: 40px;
}

.item__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.item__status {
  display: flex;
  align-items: center;
}

.btn--update {
  margin-top: 10px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
