.dashboard{
    background-color: rgb(250, 250, 251);
    height: 100vh;
}

.dashboard__container {
    width: 100%;
    overflow-x: hidden;
}

.main__desktop--open {
    position: relative;
    left: 260px;
    width: calc(100% - 260px);
}

.main__desktop--close {
    position: relative;
    left: 0;
}

