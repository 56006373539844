.spinnerBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner__containerBtn {
  width: 10px;
  height: 10px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
