.orderSummary {
  position: fixed;
  right: 20px;
  top: 100px;
  width: 360px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  transition: opacity 0.5s;
  font-family: Arial, sans-serif;
}

@media (max-width: 768px) {
  .orderSummary {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100vh;
    max-height: 100vh;
    z-index: 100005;
    border: 0;
    width: 100%; /* Sprawia, że element zajmuje całą szerokość ekranu */
    box-sizing: border-box; /* Upewnia się, że padding i border są uwzględniane w szerokości elementu */
    overflow-x: hidden; /* Zapobiega przewijaniu poziomemu */
  }
}

.title {
  font-size: 1.5rem;
  color: #333;
  margin: 0 0 20px 0;
}

.emptyMessage {
  text-align: center;
  color: #888;
}

.orderItemSum {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  max-width: 400px; /* Ustaw maksymalną szerokość kafelka */
  box-sizing: border-box; /* Zapewnia, że padding i border są wliczane w szerokość */
}

.orderItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemName {
  flex: 1;
}

.orderItemDetails {
  display: flex;
  align-items: center;
}

.quantityBtn {
  cursor: pointer;
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  background-color: #db0c40;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.quantityBtn:hover {
  background-color: #001236;
}

.itemPrice {
  margin-right: 20px;
}

.removeBtn {
  cursor: pointer;
  transition: color 0.3s;
  margin: 10px;
  width: 50px;
  height: 30px;
  font-size: 15px;
}

.totalAmount {
  font-weight: bold;
  margin-top: 20px;
}

.orderForm {
  margin-top: 20px;
}

.formGroup {
  margin-bottom: 15px;
}

.fullWidthInput {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.fullWidthInput:focus {
  border-color: #001236;
  outline: none;
}

.inputError {
  border-color: #db0c40;
}

.error {
  color: #db0c40;
  font-size: 14px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.confirmBtn,
.paymentBtn,
.editBtn {
  width: 100%;
  padding: 20px;
  background-color: #db0c40;
  color: white;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin: 0;
}

.confirmBtn:hover,
.paymentBtn:hover,
.editBtn:hover {
  background-color: #001236;
}

.confirmationButtons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
}

.fullWidthLink {
  width: 100%;
  text-decoration: none;
}

.message {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .orderItemSum {
    width: 100%; /* Upewnij się, że na mniejszych ekranach szerokość jest 100% */
  }
}

.orderSummaryClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: #db0c40;
}
