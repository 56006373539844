:root {
  --first-color: #001236;
  --second-color: #db0c40;
  --btn-width: 120px;
}

/* 
* AUDIT
* 05.06.2024
* PASS 
*/
