.main {
  background-color: rgb(250, 250, 251);
  border-top: 1px solid rgb(230, 235, 241);
  height: 100vh;
  flex: 1;
}

.main__footer {
  flex-shrink: 0;
  bottom: 0;
  margin-top: 20px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
