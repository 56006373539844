.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.background {
  position: relative;
  width: 100%;
  height: 50%;
  background-color: #3498db;
  background-size: cover;
  background-position: center;
  z-index: 10;
  transition: top 0.3s, opacity 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  padding-bottom: 20px;
}

.logo {
  width: 200px;
  margin: 25px;
}

.scrolled {
  top: -20vh;
  opacity: 0;
}

.locationHeader {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 25px 25px 0 25px;
}

.locationHeader__image {
  width: 200px;
  height: auto;
  border-radius: 50%;
}

.containerCart {
  display: flex;
  align-items: center;
  margin: 25px;
  cursor: pointer;
}

.stickyContainerCart {
  display: flex;
  margin-right: 20px;
}

.cartButton,
.stickyCartButton {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 10px;
  opacity: 0;
}

.cartIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-left: 20px;
}

.cartIcon:hover {
  background-color: #f0f0f0;
}

.cartItemCount {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.cartTotalPrice {
  margin-right: 10px;
}

.language {
  margin-left: 25px;
}

.cartButton.fadeIn,
.stickyCartButton.fadeIn {
  animation: fadeIn 0.8s forwards;
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cartButton.fadeOut,
.stickyCartButton.fadeOut {
  animation: fadeOut 0.8s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.stickyHeader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 5000;
  opacity: 1;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
}

.stickyHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.stickyLogo {
  width: 150px;
  margin: 0 25px;
}

.categoriesBar {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.categoryLink {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
}

.categoryLink:hover {
  color: #001236;
}

.categoriesBar::-webkit-scrollbar {
  height: 6px;
}

.categoriesBar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.categoriesBar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.locationName,
.locationDescription {
  margin: 0;
  padding: 0;
}

@media (max-width: 768px) {
  .logo {
    width: 125px;
    margin: 15px;
  }

  .locationHeader__image {
    width: 100px;
  }

  .cartIcon,
  .cartItemCount,
  .cartTotalPrice,
  .stickyCartButton,
  .fadeIn,
  .fadeOut {
    display: none;
  }

  .background {
    height: 50%;
  }

  h1 {
    font-size: 30px;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 0;
    }
  }
}
