.user {
  background-color: rgb(250, 250, 251);
}

.user__btn {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

/* - AUDIT
- 05.06.2024
- PASS */
