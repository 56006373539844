@media (max-width: 1000px) {
  .actionDesktopButton__container {
    display: none;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
