.card {
  width: 200px;
  height: 250px;
  margin: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.linkContainer{
  display: flex;
  flex-direction: column;
}

.link {
  display: flex;
  text-decoration: none;
}

.btn {
  margin-bottom: 10px;
}