.profileButton {
  display: flex;
  align-self: center;
}

.profileButton span {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-top: 20px;
}

.profileButton span:hover {
  cursor: pointer;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
