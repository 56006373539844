.packageSelector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
}

.packageSelector__container {
  display: flex;
}

.content__column {
  display: flex;
}

@media (max-width: 1000px) {
  .packageSelector {
    margin: 0;
  }
  .content__column {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
} 

/***
 * AUDIT
 * 16.05.2024
 * PASS
 ***/
