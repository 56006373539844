.infoMessage {
  display: flex;
  align-items: center;
}

.infoMessage__icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  border-radius: 10px;
}

.infoMessage__icon--close {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
  font-size: 30px;
  text-align: right;
}

.infoMessage__deep {
  margin-top: 10px;
  text-align: justify;
  line-height: 1.5;
}

@media (max-width: 1000px) {
  .infoMessage__icon {
    top: 80px;
    width: 80%;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
