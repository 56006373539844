/* Category */
.content {
  padding: 20px;
}

.category {
  margin-bottom: 20px;
}

/* Card */
.cardContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

p {
  padding: 0;
  margin: 0;
}
