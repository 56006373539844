.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.messageContainer > * {
  margin-bottom: 20px;
}

.stickyLogo {
  width: 150px;
  height: auto;
  margin: 25px;
}

.cartItemCount {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartTotalPrice {
  margin-right: 10px;
}

.cartMobileContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  box-sizing: border-box;
  bottom: 10px;
  border-radius: 10px;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
}

.cartItemCountMobile {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.cartButtonMobile {
  margin-right: 10px;
}

.cartTotalPriceMobile {
  margin-right: 10px;
}

@media (min-width: 760px) {
  .mobileView {
    display: none;
  }
}
