.userForm {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.avatarContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.avatar {
  width: 200px;
  height: 200px;
  margin: 20px;
  border-radius: 50%;
}

.avatarLabel {
  margin: 5px;
}

.avatarBtn {
  display: flex;
  justify-content: center;
}

.errorMessage {
  text-align: center;
  color: red;
  margin-bottom: 10px;
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.btn {
  display: flex;
  justify-content: center;
}

.myInput {
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  resize: vertical;
}

@media (min-width: 768px) {
  .columns {
    grid-template-columns: 1fr 1fr;
  }
}

/* - AUDIT
- 05.06.2024
- PASS */
