@media (min-width: 1000px) {
  .btn--refresh {
    margin-right: 10px;
    margin-left: 10px;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
