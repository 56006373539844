.horizontal__stepper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps {
  display: flex;
  justify-content: space-between;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step.active .step-number {
  background-color: #db0c40;
  color: white;
}

.step.completed .step-number {
  background-color: #001236;
  color: white;
}

.step-number {
  width: 30px;
  height: 30px;
  margin: 0 20px;
  border: 2px solid transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.actions {
  display: flex;
  margin-bottom: 40px;
}

.btn {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #db0c40;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn:hover {
  background-color: #001236;
}

.btn:disabled:hover {
  background-color: #ccc;
}

@media (max-width: 1000px) {
  .step-number {
    width: 20px;
    height: 20px;
  }
}
/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
