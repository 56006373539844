.icon {
  font-size: 25px;
}

.icon__background {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.btn {
  text-align: center;
  width: 120px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Przed 23 */

.btn__container {
  display: flex;
  justify-content: end;
}

.grid__form {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid__item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  left: 260px;
  width: calc(100% - 260px);
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading__info {
  margin-bottom: 20px;
}

/* Record status */
.dot--red {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 20px;
}

.dot--green {
  width: 15px;
  height: 15px;
  background-color: green;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 20px;
}

.error {
  color: red;
  margin-top: 5px;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 800px) {
  .grid__item {
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
