.title {
  text-align: center;
  margin-top: 40px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.label {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
}

.select {
  padding: 10px;
  font-size: 1em;
}

.button {
  padding: 10px 20px;
  font-size: 1em;
  background-color: var(--second-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.button:hover {
  background-color: var(--first-color);
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.styles.message {
  margin-top: 20px;
}

.btbContainer {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.company {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.location {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

h3 {
  margin-right: 10px;
  margin-left: 10px;
}

.mySelect {
  height: 40px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  resize: vertical;
  margin-top: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
    no-repeat right 10px center;
  background-size: 10px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
