.delivery {
  width: 600px;
  display: flex;
  justify-content: center;
}

.deliveryForm {
  width: 100vw;
}

.deliveryForm__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: center;
}

.btn__delivery {
  display: flex;
  justify-self: center;
  justify-content: center;
  margin-top: 30px;
  width: 270px;
  padding: 15px;
}

.btn__delivery--deactive {
  background-color: #db0c40;
}

.btn__delivery--active {
  background-color: #001236;
}

.btn__delivery:hover {
  background-color: #001236;
}

@media (max-width: 1000px) {
  .deliveryForm__container {
    grid-template-columns: 1fr;
    margin: 0 20px;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
