.license {
    background-color: rgb(250, 250, 251);
}

.license__btn {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.license__btn .desktop-buttons {
    display: none;
}

.license__btn .actionButtons .context__menu {
    display: none;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/