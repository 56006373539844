.form__container {
  display: grid;
  justify-items: center;
  padding: 20px;
}

form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 360px;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  margin-top: 10px;
  padding: 10px;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #8321da;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkbox {
  display: flex;
  flex-direction: row;
}

@media (max-width: 400px) {
  .form__container {
    padding: 0px;
  }

  form {
    width: 260px;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
