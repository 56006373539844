@media (max-width: 1000px) {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }
}

p {
  color: #757575;
  padding-left: 20px;
  margin: 0;
  font-size: 0.9rem;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
