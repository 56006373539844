.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 360px;
  max-width: 80%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: #000;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.modal__btn--pay {
  margin-right: 10px;
}

.modal__container--btn {
  display: flex;
  justify-content: center;
}

.modal__message {
  margin-top: 20px;
}

.modal__element--mobile {
  display: flex;
  justify-content: center;
}

.modal__div--mobile {
  display: flex;
  flex-direction: column;
  justify-self: center;
}

.modal__form {
  display: flex;
  justify-items: center;
  text-align: center;
  width: 100%;
  margin-top: 10px;
}

.modal__btn {
  margin: 0;
  width: 100%;
}

form {
  flex-direction: column;
}

label {
  margin-bottom: 5px;
  text-align: center;
}

input {
  margin-bottom: 15px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: #db0c40;
  color: white;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #001236;
}

select {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: #fff;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
