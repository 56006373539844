.actionMobileButton__container {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.actionMobileButton__contextMenu {
  margin-right: 50px;
  margin-bottom: 20px;
  position: absolute;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.actionMobileButton__contextItemMenu {
  margin: 0;
}

.btn--mobile {
  margin-bottom: 5px;
}

.btn--edit {
  margin-bottom: 5px;
}

@media (min-width: 1000px) {
  .actionMobileButton__container {
    display: none;
  }

  .company__btn .desktop-buttons {
    display: block;
  }

  .company__btn .actionMobileButton__container {
    display: none;
  }
}

@media (max-width: 1000px) {
  .company__btn .actionMobileButton__container .icon__background {
    display: block;
  }

  .company__btn .actionMobileButton__container .context__menu {
    display: flex;
    flex-direction: column;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
