.title {
  text-align: center;
  margin-top: 40px;
}

.card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.message {
  margin-top: 20px;
}
