.footer {
    background-color: #333;
    color: #fff;
    bottom: 0;
    padding: 20px 0;
    position: relative;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;

}

.footer p {
    margin: 0;
    padding: 0;
    color: #fff;
}

.footer__name {
    color: #8321da;
}