.profileOption {
  position: absolute;
  margin-top: 10px;
  margin-right: 10px;
  top: 100%;
  right: 0;
  width: 260px;
  height: 330px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 99999999;
}

.profileOption__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
}

.profileOption__personal {
  display: flex;
  align-items: center;
}

.profileOption__avatar {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.profileOption__details {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.profileOption__details--roles {
  padding-top: 5px;
  color: #757575;
}

.profileOption__details--settings {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px;
}

.profileOption__person {
  font-size: 30px;
  color: #8321da;
}

.profileOption__profile {
  font-size: 20px;
}

.profileOption__logout {
  font-size: 30px;
  color: #757575;
}

.profileOption__button {
  list-style: none;
  text-decoration: none;
  color: black;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
