.message__container {
  text-align: center;
}

.message--info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
}

.company__tiltle {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.background--white {
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.company__form {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__create {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  left: 260px;
  width: calc(100% - 260px);
}

@media (max-width: 1000px) {
  .form__create {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.btn--new {
  margin-top: 10px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
