.summaryContainer {
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f0f0;
  padding: 30px 20px;
  height: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.summaryContainer > div {
  margin-top: 10px;
  text-align: justify;
}


  