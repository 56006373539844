.nav-wrapper {
  position: relative;
  width: 100%;
}

.hamburger {
  cursor: pointer;
  display: none;
  padding: 20px;
  z-index: 1100;
}

.hamburger-icon {
  font-size: 30px;
  color: #333;
}

.nav__container {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav__container li a {
  text-decoration: none;
  color: #333;
  padding: 15px 20px;
  display: block;
  font-size: 16px;
  transition: color 0.3s, background-color 0.3s;
}

.nav__container li a:hover,
.nav__container li a:active {
  background-color: #f4f4f4;
  color: #000;
}

.nav__container li a.active {
  color: #000;
  font-weight: bold;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
    justify-content: end;
  }

  .nav__container {
    position: fixed;
    width: 250px;
    height: 100%;
    top: 0;
    left: -250px;
    background: white;
    transition: left 0.3s ease-in-out;
    z-index: 1100;
    justify-content: start;
  }

  .nav__container.open {
    left: 0;
  }

  .nav__container li a {
    border-bottom: 1px solid #eaeaea;
  }
}

@media (min-width: 769px) {
  .nav-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .nav__container {
    flex-direction: row;
    align-items: center;
    padding: 20px 10px 20px 0;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
