.languageDropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 10px 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.languageDropdown:hover {
  background-color: #f0f0f0;
}

.languageButton {
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.languageButton:hover {
  background: none;
}

.dropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  z-index: 1000;
}

.dropdownMenu button {
  color: black;
  background-color: white;
  padding: 8px 16px;
  cursor: pointer;
  text-align: left;
  margin-top: 5px;
}

.dropdownMenu button:hover {
  background-color: #f0f0f0;
}

.buttonFlag {
  margin: 0;
  padding: 0;
}

.flag {
  width: 24px;
  height: auto;
  cursor: pointer;
}
