.license__h1 {
  padding: 20px;
  margin: 20px;
  text-align: center;
}
/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
