

/* Card */

.cardBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  max-width: 360px;
  height: auto;
  cursor: pointer;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  background-color: #fff;
}

.cardBox:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.cardDescription {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

/* .cardImage {
  margin-left: 1rem;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
} */

.cardAction {
  display: flex;
  align-items: center;
}

button {
  width: 3rem;
  height: 2rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

p {
  padding: 0;
  margin: 0;
  font-size: 0.875rem;
  color: #333;
}

/* Mobile View */
@media (max-width: 760px) {
  .cardBox {
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  

  .cardDescription {
    align-items: center;
    text-align: center;
  }

  .cardAction {
    justify-content: center;
    margin-top: 0.5rem;
  }

  button {
    width: 100%;
    padding: 0.5rem 0;
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }


}

@media (max-width: 960px) {
  .desktopView{
    display: none;
  }
}

@media (min-width: 960px) {
  .mobileView{
    display: none;
  }
}

.cardImage {
  width: 100%; /* Obraz zajmuje całą szerokość kontenera */
  height: 180px; /* Stała wysokość */
  margin-left: 0;
  margin-bottom: 1rem;
  border-radius: 10px; /* Zaokrąglone rogi */
  object-fit: cover; /* Dopasowanie obrazu, aby wypełnił kontener */
}

.cardSingleImage{
  position: relative;
    width: 100%;
    max-width: 360px;
    height: auto;
    cursor: pointer;
  
  
  }
  
  .cardButtonPlus{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 30%;
    height: 30%;
    border: 1px solid white;
  }
