.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btn {
  display: flex;
  justify-content: center;
}

.myInput {
  height: 40px;
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 16px;
  resize: vertical;
}

.passwordRequirements {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.passwordError {
  color: red;
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

/* - AUDIT
- 05.06.2024
- PASS */
