.pagination__container {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

.pagination__item {
  margin: 0 2px;
}

.pagination__link {
  text-decoration: none;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: block;
  width: 30px;
  text-align: center;
}

.pagination__link:hover {
  background-color: #f0f0f0;
}

.active .pagination__link {
  background-color: #001236;
  color: #fff;
  border-color: #001236;
}

.active .pagination__link:hover {
  background-color: #db0c40;
  border-color: #db0c40;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
