body,
html {
  margin: 0;
  font-family: Arial, sans-serif;
}

/* Mobile */
.sidebar__mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  z-index: 99999;
  background-color: #fff;
  overflow-x: hidden;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

.sidebar__mobile li {
  list-style: none;
  padding: 10px;
  display: flex;
  align-items: center;
}

.sidebar__mobile a {
  text-decoration: none;
  color: rgb(38, 38, 38);
  padding-left: 10px;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.57;
}

.sidebar__mobile .btn__mobile--close {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.open-btn-mobile {
  display: none;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
  border: none;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 8px;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.btn__mobile--close {
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding: 8px;
  width: 25px;
  height: 25px;
  color: #000;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
}

@media (max-width: 1000px) {
  .sidebar__mobile {
    display: flex;
  }

  .open-btn-mobile {
    display: flex;
  }

  .btn__mobile--close {
    display: flex;
  }
}

/* Sidebar - desktop */
.sidebar__desktop {
  height: 100%;
  position: fixed;
  /* wczesniej było fixed absolute */
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  /* padding-top: 10px; */
  width: 260px;
  border-right: 1px solid rgb(230, 235, 241);
  overflow-y: auto;
}

.sidebar__logo {
  display: flex;
  justify-content: center;
}

.sidebar__logo img {
  width: 150px;
  height: auto;
  padding: 20px;
}

.sidebar__desktop ul {
  padding: 10px;
  margin: 0;
}

.sidebar__desktop li {
  list-style: none;
  padding: 10px;
  display: flex;
  align-items: center;
}

.sidebar__desktop a {
  text-decoration: none;
  color: rgb(38, 38, 38);
  padding-left: 10px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.57;
}

.sidebar__desktop .btn__desktop--close {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 36px;
  cursor: pointer;
}

.btn__desktop--close {
  display: none;
  position: fixed;
  top: 0;
  left: 260px;
  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 10px;
  width: 25px;
  height: 25px;
  color: #000;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  z-index: 9990;
}

.open-btn-desktop {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 10px;
  top: 8px;
  left: 10px;
  width: 25px;
  height: 25px;
  color: #000;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
}

@media (min-width: 1000px) {
  .btn__desktop--close {
    display: flex;
  }

  .open-btn-desktop {
    display: flex;
  }
}

@media (max-width: 1000px) {
  .sidebar__desktop {
    display: none;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
