.header {
  position: relative;
  left: 260px;
  width: calc(100% - 260px);
}

.header__container {
  display: flex;
  justify-content: end;
  padding: 18px;
  height: 25px;
}

.user__profile {
  display: flex;
  align-items: center;
}

.icon__profile--notification {
  font-size: 30px;
  padding-right: 10px;
  cursor: pointer;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
