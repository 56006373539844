.toggleButton__mobile {
  display: none;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #000;
  border: none;
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 8px;
  background-color: rgb(245, 245, 245);
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.toggleButton__desktop {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 999;
  padding: 10px;
  top: 8px;
  left: 10px;
  width: 25px;
  height: 25px;
  color: #000;
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
}

@media (max-width: 1000px) {
  .toggleButton__mobile {
    display: flex;
  }
}

@media (min-width: 1000px) {
  .toggleButton__desktop {
    display: flex;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
