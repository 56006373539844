.title {
    text-align: center;
    margin-top: 40px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .label {
    font-size: 1.2em;
    font-weight: bold;
    margin: 0;
  }
  
  .select {
    padding: 10px;
    font-size: 1em;
  }
  
  .button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: var(--second-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .button:hover {
    background-color: var(--first-color);
  }
  
  .card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .styles.message {
    margin-top: 20px;
  }
  
  .btbContainer {
    display: flex;
    justify-content: center;
  }
  
 