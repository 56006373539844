.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  
}

.modalContent {
  background: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  animation: fadeIn 0.3s ease;
  position: relative;
}

.closeButton{
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #db0c40;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.closeBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #db0c40;
}

.closeBtn:hover {
  color: #001236;
}

.modalMenuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}



.modalMenuItemImage {
  width: 100%;
  height: auto;
  max-height: 220px;
  object-fit: cover;
  border-radius: 12px;
  margin: 15px;
}

.menuItemTitle {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.ingredients {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.orderBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.quantityBtn__container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.quantityBtn {
  cursor: pointer;
  width: 36px;
  height: 36px;
  border: none;
  background-color: #db0c40;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
}

.quantityBtnPlus {
  margin-left: 0;
}

.quantityBtn:hover {
  background-color: #001236;
}

.quantityDisplay {
  margin: 0 10px;
  font-size: 18px;
  font-weight: bold;
}

.dynamicPrice {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.active .pagination__link:hover {
  background-color: #db0c40;
  border-color: #db0c40;
}

.addBtn {
  height: 40px;
  width: 100%;
  background-color: #db0c40;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.addBtn:hover {
  background-color: #001236;
}

.addBtn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.icon {
  margin-left: 5px;
  font-size: 20px;
}

.description {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}
