.company__btn {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.company__btn .desktop-buttons {
    display: none;
}

.company__btn .actionButtons .context__menu {
    display: none;
}