.message--success {
    color: #4caf50;
}

.message--warning {
    color: #ff9800;
}

.message--error {
    color: #f44336
}

.error__message{
    text-align: center;
}