.categoryForm {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin: 0;
  padding: 0;
  
}

@media (min-width: 768px) {
  .columns {
    grid-template-columns: 1fr 1fr;
  }
}

.btn {
  display: flex;
  justify-content: center;
}

.mySelect, .myInput {
    height: 40px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 16px;
    resize: vertical;
    
  }
  
  .mySelect {
    margin-top: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 10px center;
    background-size: 10px;
  }

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
