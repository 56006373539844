.logout__automatic {
  display: flex;
  justify-content: center;
  margin: 20px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
