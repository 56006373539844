.footer {
  background-color: #000000;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.logo {
  width: 100px;
}

.copyright{
  display: flex;
}

.copyright > p {
  color: #ffffff;
  margin-top: 10px;
}

.copyright > p:nth-of-type(2) {
  margin-left: 10px;
}