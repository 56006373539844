.singleLicense__box {
    display: flex;
    justify-content: center;
    margin: 10px 20px 20px 20px;
    padding: 40px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.singleLicense__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.singleLicense__field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.singleLicense__field input {
    width: 300px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .singleLicense__field {
        grid-template-columns: 1fr;
    } 
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/