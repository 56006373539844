.locationForm {
  display: flex;
  justify-content: center;
  margin: 20px;
  padding: 40px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.columns {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

@media (min-width: 768px) {
  .columns {
    grid-template-columns: 1fr 1fr;
  }
}

input {
  width: 100%;
}

.submitBtn {
  display: flex;
  justify-content: center;
}

button {
  padding: 10px 20px;
  background-color: var(--second-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: var(--btn-width);
}

button:hover {
  background-color: var(--first-color);
}

.styles.message {
  margin-top: 20px;
}

/* 
* AUDIT
* 05.06.2024
* PASS 
*/
