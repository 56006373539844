.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content > * {
  padding: 15px;
}

.content__h2 {
  margin: 20px;
}

.content__p {
  font-size: 16px;
  padding: 8px;
  width: 360px;
  margin: 10px;
  text-align: center;
}

.btn__summary {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.btn__summary--deactive {
  background-color: #db0c40;
}

.btn__summary--active {
  background-color: #001236;
}

.btn__summary:hover {
  background-color: #001236;
}

.btn__summary--disabled {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.finish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px;
  padding: 100px;
}

.finish__message {
  margin-bottom: 20px;
}

/***
 * AUDIT
 * 16.05.2024
 * PASS
 ***/
