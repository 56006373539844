.arrow {
  float: right;
}

.nestedMenu__container {
  font-family: Arial, sans-serif;
}

.menu__item {
  font-size: 20px;
  margin: 20px 20px 20px 0;
  cursor: pointer;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 180px;
}

.menu__item:hover {
  background-color: #e0e0e0;
}

.submenu {
  margin-left: 20px;
}

.submenu__item {
  padding: 10px;
  border-radius: 10px;
}

.submenu__item:hover {
  background-color: #dcdcdc;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
