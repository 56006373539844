.container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
  }
  
  .column {
    flex: 1;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .column h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .orderItem {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 15px;
    padding-top: 10px; /* Dodano mniejszy padding na górze */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    border-top: 2px solid #db0c40; /* Dodano widoczną górną krawędź */
  }
  
  .orderItem:hover {
    transform: scale(1.02);
  }
  
  .orderDetails {
    max-width: 70%;
  }
  
  .buttons {
    display: flex;
    gap: 10px;
  }
  
  .prevButton, .nextButton {
    background-color: #db0c40;
    color: #fff;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out;
  }
  
  .prevButton:hover, .nextButton:hover {
    background-color: #001236;;
  }
  
  .noOrdersText {
    color: #777;
    font-style: italic;
    text-align: center;
  }
  
  .loader {
    font-size: 1.5rem;
    color: #555;
    text-align: center;
    padding: 20px;
  }
  