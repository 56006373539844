.profileHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profileHeader__avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.profileHeader__name {
  padding-left: 10px;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
