.singleLicense__message {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.modal__status {
  display: flex;
  justify-items: center;
}

.modal__header {
  display: flex;
  align-items: center;
}

.modal__header > h1 {
  text-align: center;
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
