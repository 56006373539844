.card {
  width: 200px;
  height: 250px;
  margin: 10px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s;
  position: relative;
}

.card__mini {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.card--mini {
  width: 200px;
  height: 20px;
  margin: 5px;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s;
  position: relative;
}

.card--non-functional {
  width: 200px;
  height: 250px;
  margin: 10px;
  padding: 20px;
  border: 2px solid #001236;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* .card__summary {
    width: 300px;
    height: 350px;
    margin: 10px;
    padding: 20px;
    border: 2px solid #001236;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
} */

.card--selected {
  border-color: #001236;
}

.card__title {
  position: absolute;
  top: 20px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card__content {
  font-size: 16px;
  text-align: center;
}

.summary > p {
  padding: 5px;
  color: #000;
}

.summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.summary__details {
}

.summary__card {
  width: 300px;
  height: auto;
  margin: 10px;
  padding: 10px;
  border: 2px solid #001236;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.summary__card--p {
  padding: 0;
  line-height: 1.5;
}

.summary__size--p {
  padding: 0;
  line-height: 1.5;
}

/* Card with a additions*/
.summary__additions {
  width: 300px;
  height: auto;
  margin: 10px;
  padding: 10px;
  border: 2px solid #001236;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.summary__card > p {
  color: black;
  padding: 0;
  line-height: 1.5;
}

.summary__card--condition > p {
  color: black;
  padding: 0;
  line-height: 1.5;
}

.summary__additions > p {
  color: black;
  padding: 0;
  line-height: 1.5;
}

.summary__additions--items > p {
  color: black;
  padding: 0;
  line-height: 1.5;
}

/* Card with a delivery*/
.summary__right {
  display: flex;
  flex-direction: column;
}

.summary__delivery {
  width: 300px;
  height: auto;
  margin: 10px;
  padding: 20px;
  border: 2px solid #001236;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.summary__delivery > h2 {
  padding: 0;
  margin: 0 0 10px 0;
}

.summary__delivery > p {
  color: black;
  font-size: 16px;
  padding: 0;
  line-height: 1.5;
}

.summary__delivery--p {
  padding: 0;
  line-height: 1.5;
}

/* Card with a sum*/
.summary__fee {
  width: 300px;
  height: auto;
  margin: 10px;
  padding: 20px;
  border: 2px solid #001236;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.summary__fee > p {
  color: black;
  padding: 0;
  line-height: 1.5;
}

.summary__fee--p {
  padding: 0;
  line-height: 1.5;
}

@media (max-width: 1000px) {
  .summary {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/***
 * AUDIT
 * 05.06.2024
 * PASS
 ***/
